
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../mixins/StackedForm';
import DisplayMode from '../../mixins/DisplayMode';
import DisplayIdentifier from '../../mixins/DisplayIdentifier';
import { InputType } from '@/enums/InputType';
import { Tag } from '@/interfaces/models/Tag';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
@Component({
  components: { VFormBuilder },
})
export default class OptionForm extends mixins(StackedForm, DisplayMode, DisplayIdentifier) {
  @Prop({ type: Array, required: true }) public tags!: Tag[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
  };

  get items() {
    return [
      { name: 'backgroundImage', type: InputType.File, label: 'option.form.image', default: null, accept: 'image/*' },
      { name: 'name', type: InputType.Language, useTextarea: true, label: 'option.form.name', rules: 'required' },
      { name: 'externalId', type: InputType.Text, label: 'option.form.externalId' },
      { name: 'description', type: InputType.Language, useTextarea: true, label: 'option.form.description' },
      { name: 'shortDescription', type: InputType.Language, useTextarea: true, label: 'option.form.shortDescription' },
      { name: 'isActive', type: InputType.Checkbox, label: 'option.form.active', default: true },
      { name: 'virtual', type: InputType.Checkbox, label: 'option.form.virtual', default: false },
      { name: 'visible', type: InputType.Checkbox, label: 'option.form.visible', default: true },
      {
        name: 'displayMode',
        type: InputType.Select,
        label: 'option.form.displayMode',
        items: this.displayModes,
        default: 'default',
      },
      {
        name: 'displayModeSot',
        type: InputType.Select,
        label: 'option.form.displayModeSot',
        items: this.displayModes,
        default: 'default',
      },
      {
        name: 'displayModeMobile',
        type: InputType.Select,
        label: 'option.form.displayModeMobile',
        items: this.displayModes,
        default: 'default',
      },
      {
        name: 'displayIdentifiers',
        type: InputType.Select,
        label: 'option.form.displayIdentifiers',
        items: this.displayIdentifiers,
        multiple: true,
      },
      { name: 'tags', type: InputType.Select, label: 'option.form.tags', items: this.tagItems, multiple: true },
      { name: 'sortOrder', type: InputType.Text, label: 'option.form.sort', rules: 'integer', default: 1 },
      {
        name: 'printerSortOrder',
        type: InputType.Text,
        label: 'option.form.printerSort',
        rules: 'integer',
        default: 1,
      },
      { name: 'limit', type: InputType.Text, label: 'option.form.limit', default: 1, rules: 'required' },
      {
        name: 'requiredAmount',
        type: InputType.Text,
        label: 'option.form.requiredAmount',
        default: 1,
        rules: 'required',
      },
      {
        name: 'hasMultiple',
        type: InputType.Checkbox,
        label: 'option.form.hasMultiple',
        default: false,
        rules: 'required',
      },
    ];
  }

  get tagItems() {
    return this.tags.map((t: Tag) => {
      return {
        value: t._id,
        text: this.$options.filters!.localized(t.name),
      };
    });
  }

  public getData() {
    const data: any = { ...this.$refs.form.form };
    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();

    if (data.backgroundImage === null) {
      delete data.backgroundImage;
    }

    // @ts-ignore
    if (this.initialValues) {
      if (
        // @ts-ignore
        this.initialValues.backgroundImage &&
        // @ts-ignore
        this.initialValues.backgroundImage.thumb &&
        // @ts-ignore
        this.initialValues.backgroundImage.thumb.length > 0 &&
        filesToRemove.indexOf('backgroundImage') > -1
      ) {
        data.backgroundImage = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
    }

    return data;
  }
}

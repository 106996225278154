import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormatPriceInObject extends Vue {
  public deepSearchItems(object: any, key: string, predicate: any): any {
    let ret: any = [];
    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
      ret = [...ret, object];
    }
    if (Object.keys(object).length) {
      // tslint:disable-next-line
      for (let i = 0; i < Object.keys(object).length; i++) {
        const value = object[Object.keys(object)[i]];
        if (typeof value === 'object' && value != null) {
          const o = this.deepSearchItems(object[Object.keys(object)[i]], key, predicate);
          if (o != null && o instanceof Array) {
            ret = [...ret, ...o];
          }
        }
      }
    }
    return ret;
  }
}


import { Component } from 'vue-property-decorator';
import Editable from '@/mixins/Editable';
import { mixins } from 'vue-class-component';
import VWrapper from '@/components/shared/VWrapper.vue';
import EditOptionLoader from '../../components/option/EditOptionLoader.vue';
import OptionForm from '@/components/option/OptionForm.vue';
import OptionArticleTable from '@/components/option/article/OptionArticleTable.vue';
import OptionArticleForm from '@/components/option/article/OptionArticleForm.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { EditOption as EditOptionMixin } from '@/mixins/EditOption';

import SimpleOptionArticleForm from '@/components/option/article/SimpleOptionArticleForm.vue';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import EditSimpleOptionLoader from '@/components/option/EditSimpleOptionLoader.vue';

@Component({
  components: {
    EditSimpleOptionLoader,
    SimpleOptionArticleForm,
    OptionArticleForm,
    OptionArticleTable,
    OptionForm,
    EditOptionLoader,
    VWrapper,
  },
})
export default class EditSimpleOption extends mixins(Editable, StackedForm, Notification, EditOptionMixin) {
  get optionForm() {
    return this.$refs.editOption;
  }

  public saveOptionArticle() {
    if (!this.activeOptionArticle) {
      return;
    }
    this.optionForm.validate().then(async (res: boolean[]) => {
      if (this.isValid(res)) {
        const data: Partial<OptionArticle> = { group: this.active._id, ...this.optionForm.getData() };
        await this.updateOptionArticle({ ...data, id: this.activeOptionArticle!._id });
        this.activeOptionArticle = null;
        this.optionModalVisible = false;
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}

import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DisplayMode extends Vue {
  get displayModes() {
    return [
      { value: 'default', text: this.$t('displayModes.default') },
      { value: 'launcher', text: this.$t('displayModes.launcher') },
      { value: 'smartPicker', text: this.$t('displayModes.smartPicker') },
      { value: 'detailOverlay', text: this.$t('displayModes.detailOverlay') },
      { value: 'detailDialog', text: this.$t('displayModes.detailDialog') },
      { value: 'grid', text: this.$t('displayModes.grid') },
      { value: 'paged_grid', text: this.$t('displayModes.paged_grid') },
      { value: 'circle', text: this.$t('displayModes.circle') },
      { value: 'topSeller', text: this.$t('displayModes.topSeller') },
      { value: 'matrix', text: this.$t('displayModes.matrix') },
      { value: 'banner', text: this.$t('displayModes.banner') },
      { value: 'drinkFlavor', text: this.$t('displayModes.drinkFlavor') },
      { value: 'toggleButton', text: this.$t('displayModes.toggleButton') },
      { value: 'flavor', text: this.$t('displayModes.flavor') },
      { value: 'radio', text: this.$t('displayModes.radio') },
      { value: 'checkbox', text: this.$t('displayModes.checkbox') },
      { value: 'code', text: this.$t('displayModes.code') },
    ];
  }
}

export default [
  {
    text: 'article.visible',
    value: true,
  },
  {
    text: 'article.hide',
    value: false,
  },
];


import { Component, Prop, Vue } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { convertEmptyToNull } from '@/util/helper';
import { CustomerGroup } from '@/enums/CustomerGroup';

@Component({
  components: { VFormBuilder },
})
export default class AvailabilityForm extends mixins(StackedForm) {
  @Prop({ type: Boolean, default: false }) public disableWeb!: boolean;
  @Prop({ type: Boolean, default: false }) public disableTerminal!: boolean;
  @Prop({ type: Boolean, default: false }) public disableStandard!: boolean;
  @Prop({ type: String, default: '' }) public customerGroup!: CustomerGroup;
  @Prop({ type: Boolean, default: false }) public disablePreorderDelivery!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderTakeAway!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderInside!: boolean;

  public reset() {
    if (!this.disableStandard) {
      this.$refs.standard.reset();
    }
    if (!this.disableTerminal) {
      this.$refs.terminal.reset();
    }
    this.$refs.preorder.reset();
    this.$refs.catering.reset();
  }

  public getData() {
    const data: any = {
      preorder: {
        ...convertEmptyToNull(this.$refs.preorder.form),
      },
    };

    if (!this.disableStandard) {
      data.standard = this.$refs.standard.form.standard === '' ? null : this.$refs.standard.form.standard;
    }
    if (!this.disableTerminal) {
      data.terminal = { ...convertEmptyToNull(this.$refs.terminal.form) };
    }
    if (!this.disableWeb) {
      data.web = this.$refs.web.form.web === '' ? null : this.$refs.web.form.web;
    }

    data.catering = { ...convertEmptyToNull(this.$refs.catering.form) };

    return data;
  }

  get standard() {
    return [
      {
        name: 'standard',
        type: InputType.Checkbox,
        label: 'article.form.standardAvailability',
        default: true,
      },
    ];
  }

  get terminal() {
    return [
      {
        name: 'inside',
        type: InputType.Checkbox,
        label: 'article.form.insideAvailability',
        default: true,
      },
      {
        name: 'takeAway',
        type: InputType.Checkbox,
        label: 'article.form.takeAwayAvailability',
        default: true,
      },
    ];
  }

  get preorder() {
    return [
      {
        name: 'inside',
        type: InputType.Checkbox,
        label: 'article.form.insideAvailability',
        default: true,
        visible: !this.disablePreorderInside,
      },
      {
        name: 'takeAway',
        type: InputType.Checkbox,
        label: 'article.form.takeAwayAvailability',
        default: true,
        visible: !this.disablePreorderTakeAway,
      },
      {
        name: 'delivery',
        type: InputType.Checkbox,
        label: 'article.form.deliveryAvailability',
        default: true,
        visible: !this.disablePreorderDelivery,
      },
    ];
  }

  get catering() {
    return [
      {
        name: 'takeAway',
        type: InputType.Checkbox,
        label: 'article.form.takeAwayAvailability',
        default: true,
      },
      {
        name: 'delivery',
        type: InputType.Checkbox,
        label: 'article.form.deliveryAvailability',
        default: true,
      },
    ];
  }

  get web() {
    return [
      {
        name: 'web',
        type: InputType.Checkbox,
        label: 'article.form.webAvailability',
        default: true,
      },
    ];
  }
}


import { Component } from 'vue-property-decorator';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { Article } from '@/interfaces/models/Article';
import { ExternalOrderProvider } from '@/enums/ExternalOrderProvider';

@Component({
  components: { VFormBuilder },
})
export default class UberEatsArticleOptionsForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get items() {
    return [
      {
        name: 'price',
        type: InputType.Price,
        label: 'article.form.price',
      },
      {
        name: 'takeaway',
        type: InputType.Checkbox,
        default: true,
        label: 'article.form.takeaway',
      },
      {
        name: 'delivery',
        type: InputType.Checkbox,
        default: true,
        label: 'article.form.delivery',
      },
      {
        name: 'provider',
        type: InputType.Hidden,
        default: ExternalOrderProvider.UBER_EATS,
      },
    ];
  }

  get internalInitialValues() {
    const uberEatsProvider = (this.initialValues as Article | undefined)?.externalOrderProviderSettings?.find(
      (settings) => settings.provider === ExternalOrderProvider.UBER_EATS,
    );
    if (uberEatsProvider) {
      return uberEatsProvider;
    }
    return null;
  }
}

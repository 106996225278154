
import { Component } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import { InputType } from '@/enums/InputType';
import { Nutrition } from '@/interfaces/models/Nutrition';
import { Article } from '@/interfaces/models/Article';

@Component({
  components: { VFormBuilder },
})
export default class NutritionForm extends mixins(StackedForm) {
  public $refs!: {
    nutritions: InstanceType<typeof VFormBuilder> & { getData: () => any };
  };

  public unit: string = 'G';

  public mounted() {
    if (!this.initValues?.unit) return;
    this.unit = this.initValues.unit.toUpperCase();
  }

  public reset() {
    this.$refs.nutritions.reset();
  }

  get initValues(): Nutrition | null {
    const article: Article = this.initialValues as Article;

    if (article && article.nutritions) {
      return article.nutritions;
    } else {
      return null;
    }
  }

  public onUnitChange(value: string) {
    this.unit = value.toUpperCase();
  }

  get units() {
    return [
      { text: 'G', value: 'g' },
      { text: 'ML', value: 'ml' },
    ];
  }

  get nutritions() {
    return [
      {
        name: 'unit',
        label: 'article.form.unit',
        type: InputType.Select,
        items: this.units,
        default: 'g',
        change: this.onUnitChange,
      },
      {
        name: 'portionInG',
        type: InputType.Decimal,
        default: null,
        label: `article.form.nutrition.portionIn${this.unit}`,
      },
      {
        name: 'energyKcalPerPortion',
        type: InputType.Decimal,
        default: null,
        label: 'article.form.nutrition.energyKcalPerPortion',
      },
      {
        name: 'energyKcalPer100g',
        type: InputType.Decimal,
        default: null,
        label: `article.form.nutrition.energyKcalPer100${this.unit}`,
      },
      {
        name: 'energyKjPerPortion',
        type: InputType.Decimal,
        default: null,
        label: 'article.form.nutrition.energyKjPerPortion',
      },
      {
        name: 'energyKjPer100g',
        type: InputType.Decimal,
        default: null,
        label: `article.form.nutrition.energyKjPer100${this.unit}`,
      },
      {
        name: 'fatPerPortion',
        type: InputType.Decimal,
        default: null,
        label: 'article.form.nutrition.fatPerPortion',
      },
      {
        name: 'fatPer100g',
        type: InputType.Decimal,
        default: null,
        label: `article.form.nutrition.fatPer100${this.unit}`,
      },
      {
        name: 'saturatesPerPortion',
        type: InputType.Decimal,
        default: null,
        label: 'article.form.nutrition.saturatesPerPortion',
      },
      {
        name: 'saturatesPer100g',
        type: InputType.Decimal,
        default: null,
        label: `article.form.nutrition.saturatesPer100${this.unit}`,
      },
      {
        name: 'carbohydratePerPortion',
        type: InputType.Decimal,
        default: null,
        label: 'article.form.nutrition.carbohydratePerPortion',
      },
      {
        name: 'carbohydratePer100g',
        type: InputType.Decimal,
        default: null,
        label: `article.form.nutrition.carbohydratePer100${this.unit}`,
      },
      {
        name: 'sugarPerPortion',
        type: InputType.Decimal,
        default: null,
        label: `article.form.nutrition.sugarPerPortion`,
      },
      {
        name: 'sugarPer100g',
        type: InputType.Decimal,
        default: null,
        label: `article.form.nutrition.sugarPer100${this.unit}`,
      },
      {
        name: 'proteinPerPortion',
        type: InputType.Decimal,
        default: null,
        label: 'article.form.nutrition.proteinPerPortion',
      },
      {
        name: 'proteinPer100g',
        type: InputType.Decimal,
        default: null,
        label: `article.form.nutrition.proteinPer100${this.unit}`,
      },
      {
        name: 'saltPerPortion',
        type: InputType.Decimal,
        default: null,
        label: 'article.form.nutrition.saltPerPortion',
      },
      {
        name: 'saltPer100g',
        type: InputType.Decimal,
        default: null,
        label: `article.form.nutrition.saltPer100${this.unit}`,
      },
    ];
  }

  public getData() {
    return {
      nutritions: { ...this.$refs.nutritions.form },
    };
  }
}

export default [
  {
    text: 'article.priceLowToHigh',
    value: 'asc',
  },
  {
    text: 'article.priceHighToLow',
    value: 'desc',
  },
];


import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Article } from '@/interfaces/models/Article';
import { PriceByType } from '@/interfaces/models/PriceByType';
import { PriceLockByType } from '@/interfaces/models/PriceLockByType';
import { convertEmptyToNull } from '@/util/helper';
import { PriceType } from '@/enums/PriceType';
import { Price } from '@/interfaces/models/Price';
import PriceListForm from '@/components/shared/form/PriceListForm.vue';

@Component({
  components: { VFormBuilder, PriceListForm },
})
export default class PriceByTypeForm extends mixins(StackedForm) {
  @Prop({ type: Boolean, default: false }) public isDisplayPriceByType!: boolean;
  @Prop({ type: Boolean, default: true }) public showPriceList!: boolean;
  @Prop({ type: Boolean, default: false }) public showPriceLockByType!: boolean;
  @Prop({ type: Boolean, default: false }) public disableWithPriceLockByType!: boolean;
  @Prop({ type: Boolean, default: false }) public disableTerminal!: boolean;
  @Prop({ type: Boolean, default: false }) public disableStandard!: boolean;
  @Prop({ type: Boolean, default: false }) public priceIsLocked!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderDelivery!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderTakeAway!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderInside!: boolean;
  @Prop({ type: Array, default: () => [] }) public prices!: Price[];

  public reset() {
    if (!this.disableStandard) {
      this.$refs.standard.reset();
    }
    if (!this.disableTerminal) {
      this.$refs.terminal.reset();
    }
    this.$refs.preorder.reset();
  }

  get initValues() {
    const article: Article = this.initialValues as Article;

    if (this.isDisplayPriceByType) {
      return article.displayPriceByType;
    }

    return article.priceByType;
  }

  get initValuesPriceLockByType() {
    if (!this.showPriceLockByType) {
      return null;
    }

    const article: Article = this.initialValues as Article;

    return article.priceLockByType || {};
  }

  public getData() {
    const data: PriceByType = {
      preorder: {
        ...convertEmptyToNull(this.$refs.preorder.form),
      },
    };

    if (!this.disableStandard) {
      data.standard = this.$refs.standard.form.standard === '' ? null : this.$refs.standard.form.standard;
    }
    if (!this.disableTerminal) {
      data.terminal = { ...convertEmptyToNull(this.$refs.terminal.form) };
    }

    return data;
  }

  public getDataPriceLockByType() {
    if (!this.showPriceLockByType || !this.$refs.preorderPriceLock || !this.$refs.terminalPriceLock) {
      return null;
    }

    const data: PriceLockByType = {
      standard: this.$refs.standardPriceLock.form.standard || false,
      preorder: this.$refs.preorderPriceLock.form,
      terminal: this.$refs.terminalPriceLock.form,
    };

    return data;
  }

  public getPrices() {
    return this.$refs.prices.getData();
  }

  get standard() {
    return [
      {
        name: 'standard',
        type: InputType.Price,
        label: 'article.form.standardPrice',
        default: null,
        disabled: this.disabledCallback() || this.disabledStandardPrice(),
      },
    ];
  }

  get priceType() {
    return [{ value: PriceType.EMAIL_DOMAIN, text: this.$t('article.form.emailDomain') }];
  }

  public disabledCallback() {
    if (this.priceIsLocked !== undefined) {
      return this.priceIsLocked;
    }

    return false;
  }

  public disabledStandardPrice() {
    if (this.disableWithPriceLockByType) {
      const article: Article = this.initialValues as Article;

      if (article.priceLockByType && article.priceLockByType.standard) {
        return true;
      }
    }
  }

  public disabledPreorderPrice(type?: 'inside' | 'takeAway' | 'delivery') {
    if (this.disableWithPriceLockByType && type) {
      const article: Article = this.initialValues as Article;

      if (article.priceLockByType && article.priceLockByType.preorder && article.priceLockByType.preorder[type]) {
        return true;
      }
    }

    return false;
  }

  public disabledTerminalPrice(type?: 'inside' | 'takeAway') {
    if (this.disableWithPriceLockByType && type) {
      const article: Article = this.initialValues as Article;

      if (article.priceLockByType && article.priceLockByType.terminal && article.priceLockByType.terminal[type]) {
        return true;
      }
    }

    return false;
  }

  get terminal() {
    return [
      {
        name: 'inside',
        type: InputType.Price,
        default: null,
        label: 'article.form.insidePrice',
        disabled: this.disabledCallback() || this.disabledTerminalPrice('inside'),
      },
      {
        name: 'takeAway',
        type: InputType.Price,
        default: null,
        label: 'article.form.takeAwayPrice',
        disabled: this.disabledCallback() || this.disabledTerminalPrice('takeAway'),
      },
    ];
  }

  get preorder() {
    return [
      {
        name: 'inside',
        type: InputType.Price,
        default: null,
        label: 'article.form.insidePrice',
        disabled: this.disabledCallback() || this.disabledPreorderPrice('inside'),
        visible: !this.disablePreorderInside,
      },
      {
        name: 'takeAway',
        type: InputType.Price,
        default: null,
        label: 'article.form.takeAwayPrice',
        disabled: this.disabledCallback() || this.disabledPreorderPrice('takeAway'),
        visible: !this.disablePreorderTakeAway,
      },
      {
        name: 'delivery',
        type: InputType.Price,
        default: null,
        label: 'article.form.deliveryPrice',
        disabled: this.disabledCallback() || this.disabledPreorderPrice('delivery'),
        visible: !this.disablePreorderDelivery,
      },
    ];
  }

  get priceLockByTypeStandard() {
    return [
      {
        name: 'standard',
        type: InputType.Checkbox,
        label: 'article.form.priceLockByType.standard',
        disabled: this.disabledCallback(),
      },
    ];
  }

  get priceLockByTypeTerminal() {
    return [
      {
        name: 'inside',
        type: InputType.Checkbox,
        label: 'article.form.priceLockByType.inside',
        disabled: this.disabledCallback(),
        visible: !this.disablePreorderInside,
      },
      {
        name: 'takeAway',
        type: InputType.Checkbox,
        label: 'article.form.priceLockByType.takeAway',
        disabled: this.disabledCallback(),
        visible: !this.disablePreorderTakeAway,
      },
    ];
  }

  get priceLockByTypePreorder() {
    return [
      {
        name: 'inside',
        type: InputType.Checkbox,
        label: 'article.form.priceLockByType.inside',
        disabled: this.disabledCallback(),
        visible: !this.disablePreorderInside,
      },
      {
        name: 'takeAway',
        type: InputType.Checkbox,
        label: 'article.form.priceLockByType.takeAway',
        disabled: this.disabledCallback(),
        visible: !this.disablePreorderTakeAway,
      },
      {
        name: 'delivery',
        type: InputType.Checkbox,
        label: 'article.form.priceLockByType.delivery',
        disabled: this.disabledCallback(),
        visible: !this.disablePreorderDelivery,
      },
    ];
  }
}

import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DisplayIdentifier extends Vue {
  get displayIdentifiers() {
    return [
      { value: 'extra', text: this.$t('displayIdentifiers.extra') },
      { value: 'extra15', text: this.$t('displayIdentifiers.extra15') },
      { value: 'extra30', text: this.$t('displayIdentifiers.extra30') },
      { value: 'extra_cheese15', text: this.$t('displayIdentifiers.extraCheese15') },
      { value: 'extra_cheese30', text: this.$t('displayIdentifiers.extraCheese30') },
      { value: 'salad', text: this.$t('displayIdentifiers.salad') },
      { value: 'bread', text: this.$t('displayIdentifiers.bread') },
      { value: 'cookie1', text: this.$t('displayIdentifiers.cookie1') },
      { value: 'cookie3', text: this.$t('displayIdentifiers.cookie3') },
      { value: 'cookie12', text: this.$t('displayIdentifiers.cookie12') },
      { value: 'size', text: this.$t('displayIdentifiers.size') },
      { value: 'size15', text: this.$t('displayIdentifiers.size15') },
      { value: 'size30', text: this.$t('displayIdentifiers.size30') },
      { value: 'chips', text: this.$t('displayIdentifiers.chips') },
      { value: 'sauce', text: this.$t('displayIdentifiers.sauce') },
      { value: 'cheese', text: this.$t('displayIdentifiers.cheese') },
      { value: 'drink', text: this.$t('displayIdentifiers.drink') },
      { value: 'water', text: this.$t('displayIdentifiers.water') },
      { value: 'tea', text: this.$t('displayIdentifiers.tea') },
      { value: 'hot_beverages', text: this.$t('displayIdentifiers.hotBeverages') },
      { value: 'menu', text: this.$t('displayIdentifiers.menu') },
      { value: 'main', text: this.$t('displayIdentifiers.main') },
      { value: 'sub', text: this.$t('displayIdentifiers.sub') },
      { value: 'kids', text: this.$t('displayIdentifiers.kids') },
      { value: 'wrap', text: this.$t('displayIdentifiers.wrap') },
      { value: 'sides', text: this.$t('displayIdentifiers.sides') },
      { value: 'full_price', text: this.$t('displayIdentifiers.fullPrice') },
      { value: 'inverse', text: this.$t('displayIdentifiers.inverse') },
      { value: 'code', text: this.$t('displayIdentifiers.code') },
    ];
  }
}

import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import { Article } from '@/interfaces/models/Article';

const foodcard = namespace('foodcard');
const venue = namespace('venue');

@Component
export class EditArticle extends mixins(Editable) {
  @venue.State('active') public venue!: Venue;

  @foodcard.State('activeArticle') public active!: Article;
  @foodcard.State('options') public options!: OptionGroup[];

  @foodcard.Action('fetchOptions') public getOptions!: any;
  @foodcard.Action('setOptionFilter') public setOptionFilter!: any;
  @foodcard.Action('storeArticle') public store!: any;
  @foodcard.Action('updateArticle') public update!: any;
  @foodcard.Action('changeCategory') public newCategory!: any;
  @foodcard.Action('showArticle') public show!: any;

  @foodcard.Action('uploadArticleImage') public uploadImage!: any;
  @foodcard.Action('uploadArticleIcon') public uploadIcon!: any;
  @foodcard.Action('uploadArticleBanner') public uploadBanner!: any;
  @foodcard.Action('uploadArticleIcon') public uploadleIcon!: any;
  @foodcard.Action('uploadOptionArticleImage') public uploadOptionArticleImage!: any;

  get title() {
    return this.editing ? 'article.edit' : 'article.create';
  }

  get article() {
    if (this.active && this.editing) {
      return { ...this.active, category: this.$route.params.category };
    }

    return null;
  }

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public updateAsset(venue: Venue, asset: string) {
    const accept: string = 'image/*';
    const input: any = document.createElement('input');
    input.type = 'file';
    input.accept = accept;
    input.addEventListener('change', async (handleFile: any) => {
      try {
        if (handleFile.target.files[0] instanceof File) {
          let formData: FormData;
          this.$startLoading('updateAsset');
          if (asset === 'assets') {
            formData = new FormData();
            formData.append('asset', handleFile.target.files[0]);
            await this.uploadImage({ id: venue._id, category: venue.category, image: formData });
          } else if (asset === 'banner') {
            formData = new FormData();
            formData.append('asset', handleFile.target.files[0]);
            await this.uploadBanner({ id: venue._id, category: venue.category, image: formData });
          } else if (asset === 'icon') {
            formData = new FormData();
            formData.append('asset', handleFile.target.files[0]);
            await this.uploadIcon({ id: venue._id, category: venue.category, image: formData });
          }
        }
      } finally {
        this.$stopLoading('updateAsset');
      }
    });
    input.click();
  }

  public updateOptionArticleAsset(group: string, id: string, asset: string) {
    const accept: string = 'image/*';
    const input: any = document.createElement('input');
    input.type = 'file';
    input.accept = accept;
    input.addEventListener('change', async (handleFile: any) => {
      try {
        if (handleFile.target.files[0] instanceof File) {
          let formData: FormData;
          this.$startLoading('updateAsset');
          if (asset === 'assets') {
            formData = new FormData();
            formData.append('asset', handleFile.target.files[0]);
            await this.uploadOptionArticleImage({ group, id, image: formData });
          } else if (asset === 'banner') {
            formData = new FormData();
            formData.append('asset', handleFile.target.files[0]);
          } else if (asset === 'icon') {
            formData = new FormData();
            formData.append('asset', handleFile.target.files[0]);
          }
        }
      } finally {
        this.$stopLoading('updateAsset');
      }
    });
    input.click();
  }
}

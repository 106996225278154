
import { Component, Watch } from 'vue-property-decorator';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Article } from '@/interfaces/models/Article';
import { VColorPicker } from 'vuetify/lib';

@Component({
  components: { VColorPicker },
})
export default class StyleForm extends mixins(StackedForm) {
  public color: string | null = '';
  public fontColor: string | null = '';
  public borderColor: string | null = '';
  public backgroundColor: string | null = '';
  public mode: string = 'hexa';
  public types: string[] = ['hexa', 'rgba'];

  public swatches: string[][] = [
    ['#FF0000', '#AA0000', '#550000'],
    ['#FFFF00', '#AAAA00', '#555500'],
    ['#00FF00', '#00AA00', '#005500'],
    ['#00FFFF', '#00AAAA', '#005555'],
    ['#0000FF', '#0000AA', '#000055'],
  ];

  public activeStyleOption: 'fontColor' | 'borderColor' | 'backgroundColor' = 'fontColor';

  public mounted() {
    const article: Article | null = this.initialValues as Article;

    if (article && article.styles) {
      this.fontColor = article.styles.fontColor ?? '';
      this.borderColor = article.styles.borderColor ?? '';
      this.backgroundColor = article.styles.backgroundColor ?? '';
    }
    this.color = this.fontColor;
  }

  public selectStyleOption(styleOptin: 'fontColor' | 'borderColor' | 'backgroundColor') {
    if (styleOptin === 'fontColor') {
      this.color = this.fontColor;
    } else if (styleOptin === 'borderColor') {
      this.color = this.borderColor;
    } else {
      this.color = this.backgroundColor;
    }
    this.activeStyleOption = styleOptin;
  }

  public clearStyleOption(styleOptin: 'fontColor' | 'borderColor' | 'backgroundColor') {
    if (styleOptin === 'fontColor') {
      this.fontColor = '';
    } else if (styleOptin === 'borderColor') {
      this.borderColor = '';
    } else {
      this.backgroundColor = '';
    }
    this.activeStyleOption = styleOptin;
  }

  public changeMode(mode: string) {
    this.mode = mode;
  }

  public getData() {
    return {
      styles: {
        fontColor: this.fontColor,
        borderColor: this.borderColor,
        backgroundColor: this.backgroundColor,
      },
    };
  }

  public copy() {
    if (this.color) {
      navigator.clipboard.writeText(this.color);
    }
  }

  @Watch('color')
  public async onColorChange() {
    if (this.activeStyleOption === 'fontColor') {
      this.fontColor = this.color;
    } else if (this.activeStyleOption === 'borderColor') {
      this.borderColor = this.color;
    } else {
      this.backgroundColor = this.color;
    }
  }
}
